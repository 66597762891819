import { alert } from 'o365-vue-services';

export default class CropperControl {
    private _dataObject: Object;
    /** vue-advanced-cropper unwrapped referance */
    private _cropperFunctions: Record<string, Function>;
    fieldName: string;
    aspectRatio: number;
    /** Indicates that Crpopper image is loading */
    loading: boolean;
    /** Indicates that Cropper has failed to load the blob url */
    loadFailed: boolean;
    /** Indicates that the aspect ratio is looked to the current aspectRatio property */
    aspectRatioLocked: boolean = true;

    fileName: string;
    blobUrl: string;
    progress: any;

    constructor(options: ICropperContorlOptions) {
        this._dataObject = options.dataObject;
        this.aspectRatio = options.aspectRatio;
        this.fieldName = options.fieldName;
        this._cropperFunctions = options.cropperFunctions;
    }

    setRatio(ratio: number) {
        this.aspectRatioLocked = true;
        this.aspectRatio = ratio;
    }

    loadImage(event: InputEvent) {
        const target = <HTMLInputElement>event.target;
        const files = target.files;

        if (files && files.length) {
            const file = files[0];

            if (/^image\/\w+$/.test(file.type)) {
                this.fileName = file.name;
                this.blobUrl = URL.createObjectURL(file);
            }
        }
    }

    reset(options: {
        src: string;
        fieldName: string;
        aspectRatio: number;
    }) {
        this.blobUrl = options.src;
        this.fieldName = options.fieldName;
        this.aspectRatio = options.aspectRatio;
        this.progress = null;
        this.loading = false;
        this.loadFailed = false;
    }

    async saveImage() {
        const result = this._cropperFunctions.getResult()
        const canvas: HTMLCanvasElement = result.canvas;


        let blob: Blob;
        if (this._dataObject && this._dataObject.current.Extension === 'webp') {
            blob = this.dataURItoBlob(canvas.toDataURL('image/x-png'));
        } else {
            blob = this.dataURItoBlob(canvas.toDataURL('image/jpeg'));
        }

        // if (this.fieldName) {
        //     formData.append(this.fieldName, blob);
        // } else {
        //     formData.append('FileRef', blob);
        // }

        const fileName = this.fileName ?? this._dataObject.current.FileName;
        const fileToUpload = new File([blob], fileName);

        this._dataObject.fileUpload.upload({
            files: [fileToUpload],
            onProgress: (progress) => this._updateProgress(progress),
            onCompleted: () => this.__uploadCompleted(),
        }, { PrimKey: this._dataObject.current.PrimKey });
        //this._cropperFunctions.closeModal();
    }

    async clearImage() {
        // TODO
    }

    /** Event handler for cropper component ready event */
    onCropperReady() {
        this.loading = false;
    }

    /** Event handler for cropper component error event */
    onCropperError(e) {
        this.loading = false;
        this.loadFailed = true;
    }

    /** Build a Blob from DataURL */
    private dataURItoBlob(dataURI: string) {
        let byteString: string;
        let mimeString: string;
        let ab: ArrayBuffer;
        let ia: Uint8Array;
        let bb: any;
        let dataView: DataView;
        let blob: Blob;

        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURIComponent(dataURI.split(',')[1]);
        }

        mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        ab = new ArrayBuffer(byteString.length);
        ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        if (window['BlobBuilder'] || window['MSBlobBuilder'] || window['WebKitBlobBuilder'] || window['MozBlobBuilder']) {
            const BlobBuilder = window['BlobBuilder'] || window['MSBlobBuilder'] || window['WebKitBlobBuilder'] || window['MozBlobBuilder'];
            bb = new BlobBuilder();
            bb.append(ab);
            blob = bb.getBlob(mimeString);
        } else if (typeof Blob !== 'undefined') {
            dataView = new DataView(ab);
            blob = new Blob([dataView], { type: 'mimeString' });
        } else {
            alert('Error occurred during Blob generation');
            return null;
        }

        return blob;
    }

    private _updateProgress(progress: number) {
        this.progress = progress;
    }

    private __uploadCompleted() {
        if (this.progress.error == null) {
            this._cropperFunctions.closeModal();
        }
    }
}

export interface ICropperContorlOptions {
    dataObject: DataObject;
    openModal: Function;
    closeModal: Function;
    cropperFunctions: Record<string, Function>;
    aspectRatio: number;
    fieldName?: string;
};